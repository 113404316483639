export default function chainPropTypes(propType1, propType2) {
  if (process.env.NODE_ENV === 'production') {
    return function () {
      return null;
    };
  }

  return function validate() {
    return propType1.apply(void 0, arguments) || propType2.apply(void 0, arguments);
  };
}